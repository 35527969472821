import { Route, Routes } from 'react-router';
import { Home } from './Home.js'
import { MusicaleRap } from './supportPage/MusicaleRap';
import { QuizOS } from './supportPage/QuizOS.js';

function App() {
  return <Routes>
      <Route path='/' element={<Home />} />

      <Route path='/supportPage/MusicaleRap' element={<MusicaleRap/>} />
      <Route path='/supportPage/QuizOS' element={<QuizOS/>} />
  </Routes>
}

export default App;
