import { Header } from '../components/Header';
import { SupportDescription } from '../components/SupportDescription';

export function MusicaleRap() {
    return(
        <div>
            <Header />
            <SupportDescription 
                link='https://apps.apple.com/app/id1665315515'
                title='MusicaleRap'
                description='
                    For any questions regarding app issues, general feedback, 
                    and feature enhancement requests please reach out.
                '
            />
            <h3 className='small-box-title'> Privacy Policy 
                <b><p>Indicate how email addresses collected from this app are being used by you or your third-party partners </p></b>
                <p>
                App Functionality, such as to authenticate the user, enable features, prevent fraud, implement security measures, ensure server 
                up-time, minimize app crashes, improve scalability and performance, or perform customer support
                </p>
                <p>Are the email addresses collected from this app linked to the users identity?</p>
                <p>No, email addresses collected from this app are not linked to the users identity</p>
                <b><p>Indicate how other user content collected from this app is being used by you or your third-party partners</p></b>
                <p>
                App Functionality, such as to authenticate the user, enable features, prevent fraud, implement security measures,
                ensure server up-time, minimize app crashes, improve scalability and performance, or perform customer support
                </p>
                <p>Is the other user content collected from this app linked to the users identity?</p>
                <p>No, other user content collected from this app is not linked to the users identity</p>
                <p>Do you or your third-party partners use other user content for tracking purposes?</p>
                <p>No, we do not use other user content for tracking purposes</p>
                <b><p>Indicate how user IDs collected from this app are being used by you or your third-party partners</p></b>
                <p>
                App Functionality, such as to authenticate the user, enable features, prevent fraud, implement security 
                measures, ensure server up-time, minimize app crashes, improve scalability and performance, or perform customer support
                </p>
                <p>Are the user IDs collected from this app linked to the users identity?</p>
                <p>No, user IDs collected from this app are not linked to the users identity</p>
                <p>Do you or your third-party partners use user IDs for tracking purposes?</p>
                <p>No, we do not use user IDs for tracking purposes</p>
            </h3>
        </div>
    );
}