import { Link } from "react-router-dom";

export function AppArticle({
    link, privacyPolicy, name, description
}) {
    return (
        <div className='app-article'>
            <a href={link}><h4 className='highlight'>{name}</h4></a>
            <Link to={privacyPolicy}><h5>Privacy Policy</h5></Link>
            <p lassName="light-weight">{description}</p>
        </div>
    );
 }