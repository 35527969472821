
export function AboutArticle({
    title, subTitleOne, descriptionOne, 
    subTitleTwo, descriptionTwo
}) {
    return (
        <div>
            <h4 className="highlight">{title}</h4>
            <h5>{subTitleOne}</h5>
            <p lassName="light-weight">
                {descriptionOne}
            </p>
            <h5>{subTitleTwo}</h5>
            <p className="light-weight">
                {descriptionTwo}
            </p>
        </div>
    );
}