import { Header } from './components/Header';
import { AboutArticle } from './components/AboutArticle';
import { ResourceArticle } from './components/ResourceArticle';
import { AppArticle } from './components/AppArticle';
import { Footer } from './components/Footer';

export function Home() {
    return (
        <div className='App'>
            <Header />
            <div className='section-box'>
                <p>
                    I have 2 apps on the App Store that I built and maintain. 
                </p>
                <p>
                    I use SwiftUI, Swift Package Manager,  Firebase, MVVM and 
                    RESTful APIs to build most of my projects. All of my App 
                    Store apps have 100% test coverage using XCTest.
                </p>
                <p>
                    I also have experience using CocoaPods, MongoDB, Vapor 4, 
                    and Leaf.
                </p>
                <p>
                    I built this website myself using React and used Google Domains 
                    with Firebase for web hosting. I also designed the website using 
                    Figma. 
                </p>
                <p>
                    I enjoy researching web design and development for fun.
                </p>
            </div>

            <div className='links-grid'> 
                <div className='small-box-grid'>
                    <h2 className='small-box'><a href="#iOS">iOS Apps</a></h2>
                </div>
                <div className='small-box-grid'> 
                    <h2 className='small-box'><a href="#Resources">Developer Resources</a></h2>
                </div>
                <div className='small-box-grid'>
                    <h2 className='small-box'><a href="#About">About Me</a></h2>
                </div>
            </div>

            <div className='article-section-box'>
                <h3 className='small-box-title' id='iOS'>iOS Apps</h3>

                <AppArticle 
                    link='https://apps.apple.com/app/id1665315515'
                    privacyPolicy='/supportPage/MusicaleRap'
                    name='MusicaleRap'
                    description='
                    MusicaleRap is an app for HipHop and Rap music 
                    lover to comment on new and old albums from 
                    iTunes / Apple Music. Search albums that are 
                    classified as "HipHop/Rap" by iTunes / Apple Music. 
                    Artists from the UK, the US, and more. Comment on 
                    your favorite albums using MusicaleRap and join 
                    the community of HipHop Rap lovers.
                    '
                />

                <AppArticle 
                    link='https://apps.apple.com/us/app/quizos/id1665398854'
                    privacyPolicy='/supportPage/QuizOS'
                    name='QuizOS'
                    description='
                    QuizOS is a swift quiz app for iOS / macOS developers. 
                    The app covers the basics of the Swift programming 
                    language with more than 60+ questions on the language. 
                    '
                />

            
            </div>

            <div className='article-section-box'>
                <h3 className='small-box-title' id='Resources'>Developer Resources</h3>
               
                <ResourceArticle 
                    link='https://coolors.co/'
                    name='Coolors'
                    description='
                    Create the perfect palette or get inspired by thousands of beautiful color schemes.
                    '
                />
                <ResourceArticle 
                    link='https://imagecolorpicker.com/en'
                    name='ImageColorPicker'
                    description='
                    Use the online image color picker above to select a color and get the HTML Color 
                    Code of this pixel. Also you get the HEX color code value, RGB value and HSV value. 
                    '
                />
                <ResourceArticle 
                    link='https://jsonformatter.curiousconcept.com'
                    name='JSON Formatter'
                    description='
                    The JSON Formatter was created to help folks 
                    with debugging. As JSON data is often output 
                    without line breaks to save space, it can be 
                    extremely difficult to actually read and make 
                    sense of it. This tool hoped to solve the 
                    problem by formatting and beautifying the 
                    JSON data so that it is easy to read and 
                    debug by human beings.
                    '
                />
                <ResourceArticle 
                    link='https://quicktype.io'
                    name='QuickType'
                    description='
                    Generate models and serializers from JSON, s
                    chema, and GraphQL for working with data quickly 
                    & safely in any programming language.
                    '
                />
                <ResourceArticle 
                    link='https://dribbble.com'
                    name='Dribbble'
                    description='
                    Millions of designers and agencies around the 
                    world showcase their portfolio work on Dribbble
                    - the home to the worlds best design and 
                    creative professionals.
                    '
                />
                <ResourceArticle 
                    link='https://www.figma.com/community/'
                    name='Figma Community'
                    description='
                    The Figma Community is a 
                    public space where you can now publish live 
                    design files that anyone in the world can 
                    inspect, remix, and learn from.
                    '
                />
                <ResourceArticle 
                    link='https://cocoapods.org'
                    name='CocoaPods'
                    description='
                    CocoaPods is a dependency manager for Swift and 
                    Objective-C Cocoa projects. It has over 93 thousand 
                    libraries and is used in over 3 million apps. 
                    CocoaPods can help you scale your projects elegantly.
                    '
                />
                <ResourceArticle 
                    link='https://github.com/realm/SwiftLint'
                    name='SwiftLint'
                    description='
                    A tool to enforce Swift style and conventions, 
                    loosely based on the now archived GitHub Swift 
                    Style Guide. SwiftLint enforces the style guide 
                    rules that are generally accepted by the Swift 
                    community. These rules are well described in
                    popular style guides like Ray Wenderlichs Swift 
                    Style Guide'
                />
                <ResourceArticle 
                    link='https://github.com/SwiftyJSON/SwiftyJSON'
                    name='SwiftJSON'
                    description='
                    SwiftyJSON makes it easy to deal with JSON data in Swift.
                    '
                />
                <ResourceArticle 
                    link='https://github.com/Alamofire/Alamofire'
                    name='AlamoFire'
                    description='
                    Alamofire is an HTTP networking library written in Swift.
                    '
                />
                <ResourceArticle 
                    link='https://docs.vapor.codes'
                    name='Vapor'
                    description='
                    Welcome to the Vapor Documentation! Vapor is a
                    web framework for Swift, allowing you to write 
                    backends, web apps APIs and HTTP servers in Swift. 
                    Vapor is written in Swift, which is a modern, 
                    powerful and safe language providing a number of 
                    benefits over the more traditional server languages.
                    '
                />
            </div>

            <div className='article-section-box'>
                <h3 className='small-box-title' id='About'>About Me</h3>
               
                <AboutArticle 
                    title='MSc in Software Development' 
                    subTitleOne='University of Strathclyde'
                    descriptionOne='
                        At the time, the University of Strathclyde was the 15th ranked 
                        University in the United Kingdom. I was accepted in Cardiff 
                        University, a member of the Russell Group, and Dundee University.
                    '
                    subTitleTwo='Why Strathclyde?'
                    descriptionTwo='
                        I knew I wanted to be an iOS Developer || Engineer before getting 
                        accepted. While doing research, I found out that John Giannandrea 
                        studied at Strathclyde. John Giannandrea, reports to Tim Cook, and 
                        is the Senior Vice President of Machine Learning and AI Strategy at 
                        Apple.
                    '
                />

                <AboutArticle 
                    title='United States Army' 
                    subTitleOne='Religious Affairs Specialist'
                    descriptionOne='
                        I joined the US Army two weeks after I graduated high school. I 
                        was part of the delayed entry program. Which allows young adults 
                        to join the US Army before graduating high school. Which is how 
                        I was able to leave for basic training two weeks after graduating 
                        high school.
                    '
                    subTitleTwo='Why the United States Army?'
                    descriptionTwo='
                        Both of my uncles were service members and I belived, and still believe, 
                        the military provided the greatest opportunity for me at 17 years old. 
                        I was stationed at Camp Casey in South Korea, I was a part of staff, 
                        (a group of officers assisting the commander), and was able to learn 
                        from unqiue individuals and leaders. I was also stationed at Fort 
                        Leonard Wood and Fort Jackson.
                    '
                />

                <AboutArticle 
                    title='Early Years' 
                    subTitleOne='In middle school...'
                    descriptionOne='
                        The movie The Facebook was released and the Hand Covers Bruise song 
                        by Atticus Ross and Trent Reznor played in the opening scene amazed me. 
                        I watched the movie so many times because of the opening scene I eventually 
                        fell into the world of technology. 
                    '
                    subTitleTwo='In high school...'
                    descriptionTwo='
                        I took a social media communications class that compared 
                        social network influences with Maslows Hierarchy of Needs. Our teacher 
                        explaining how the continuance intention, which is an 
                        individual need to identify a continuing use for an action or purpose led to 
                        a negative feedback loop because of human natural behavior. I found the topic 
                        so fascinating it made me realize I wanted to continue learning about technology 
                        at university. 
                    '
                />
            </div>
            <Footer />
        </div>
    ); 
}

