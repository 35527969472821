import { Header } from '../components/Header';
import { SupportDescription } from '../components/SupportDescription';

export function QuizOS() {
    return(
        <div>
            <Header />
            <SupportDescription 
                link='https://apps.apple.com/us/app/quizos/id1665398854'
                title='QuizOS'
                description='
                    For any questions regarding app issues, general feedback, 
                    and feature enhancement requests please reach out.
                '
            />
            <h3 className='small-box-title'> Privacy Policy 
                <p>Data is not collected from this app.</p>
            </h3>
        </div>
    );
}