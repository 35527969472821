
export function ResourceArticle({
    link, name, description
}) {
    return (
        <div className='resource-article'>
            <a href={link}>
                <h4 className='highlight'>{name}</h4>
            </a>
            <p lassName="light-weight">{description}</p>
        </div>
    );
 }