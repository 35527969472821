
export function SupportDescription({
    link, title, description
}) {
    return(
        <h3 className='small-box-title'>
            <a className='highlight' href={link}>{title}</a>
            <p className="light-weight">
                {description}
            </p>
        </h3>
    );
}